import type { FC } from 'react';
import classNames from 'classnames';

export type IconStyle =
    | 'fa-thin'
    | 'fa-light'
    | 'fa-regular'
    | 'fa-solid'
    | 'fa-duotone'
    | 'fa-brands'
    | 'fak';

export type IconName =
    | 'fa-angle-down'
    | 'fa-angle-up'
    | 'fa-arrow-right-long'
    | 'fa-arrow-left-long'
    | 'fa-briefcase'
    | 'fa-calendar-days'
    | 'fa-check'
    | 'fa-chevron-down'
    | 'fa-chevron-left'
    | 'fa-chevron-right'
    | 'fa-chevron-up'
    | 'fa-phone'
    | 'fa-xmark'
    | 'fa-envelope'
    | 'fa-circle-exclamation'
    | 'fa-question'
    | 'fa-gift'
    | 'fa-envelope-open-text'
    | 'fa-circle-info'
    | 'fa-minus'
    | 'fa-pencil'
    | 'fa-plus'
    | 'fa-magnifying-glass'
    | 'fa-star'
    | 'fa-trash-can'
    | 'fa-share'
    | 'fa-user'
    | 'fa-triangle-exclamation'
    | 'fa-whatsapp'
    | 'fa-magnifying-glass-minus'
    | 'fa-magnifying-glass-plus'
    | 'fa-arrow-right-from-bracket'
    | 'fa-eye'
    | 'fa-eye-slash'
    | 'fa-box'
    | 'fa-box-open'
    | 'fa-paper-plane'
    | 'fa-heart'
    | 'fa-bag-shopping'
    | 'fa-truck'
    | 'fa-truck-fast'
    | 'fa-spinner'
    | 'fa-badge-check'
    | 'fa-badge-percent'
    | 'fa-file-signature'
    | 'fa-stars'
    | 'fa-droplet'
    | 'fa-address-card'
    | 'fa-spinner-third'
    | 'fa-font'
    | 'fa-image'
    | 'fa-comment'
    | 'fa-receipt'
    | 'fa-circle-check'
    | 'fa-credit-card'
    | 'fa-ellipsis'
    | 'fa-thin-image-landscape-circle-arrow-up'
    | 'fa-thin-image-landscape-circle-check'
    | 'fa-hand-pointer'
    | 'fa-arrow-pointer'
    | 'fa-download'
    | 'fa-thin-box-open-circle-xmark'
    | 'fa-thin-badge-percent-circle-xmark'
    | 'fa-repeat'
    | 'fa-ban'
    | 'fa-info'
    | 'fa-film'
    | 'fa-circle-xmark'
    | 'fa-paintbrush-pencil'
    | 'fa-user-plus'
    | 'fa-calendar'
    | 'fa-lightbulb-on'
    | 'fa-lightbulb'
    | 'fa-face-smile-relaxed'
    | 'fa-bell-ring'
    | 'fa-cake-candles'
    | 'fa-tree-christmas'
    | 'fa-pen-to-square'
    | 'fa-expand-wide'
    | 'fa-rectangle-history';

export type IconSize =
    | 'fa-xs'
    | 'fa-sm'
    | 'fa-lg'
    | 'fa-xl'
    | 'fa-2xl';

export type IconAnimation =
    | 'fa-bounce'
    | 'fa-spin'
    | 'fa-flip-vertical';

export interface IconProps {
    animation?: IconAnimation;
    className?: string;
    name: IconName;
    size?: IconSize;
    style?: IconStyle;
}

const Icon: FC<IconProps> = ({
    animation,
    className,
    name,
    size,
    style = 'fa-light',
}) => (
    <i role="img" className={classNames([style, name, size, animation], className)}/>
);

export default Icon;
